(function () {
    document.addEventListener("DOMContentLoaded", function () {
        let options = {
            rootMargin: '0 0 0 0'
        }
        const imageObserver = new IntersectionObserver((entries, imgObserver ) => {
            entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const lazyImage = entry.target
                        // console.log("lazy loading ", lazyImage)
                        lazyImage.src = lazyImage.dataset.src
                        lazyImage.classList.remove("lzy_img");
                        imgObserver.unobserve(lazyImage);
                    }
                },
                options)
        });
        const arr = document.querySelectorAll('img.lzy_img')
        arr.forEach((v) => {
            imageObserver.observe(v);
        })
    });
})();

(function () {
    document.addEventListener("DOMContentLoaded", function () {
        let options = {
            rootMargin: '0 0 0 0'
        }
        const imageObserver2 = new IntersectionObserver((entries, imgObserver2) => {
            entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const lazyImage2 = entry.target
                        // console.log("lazy loading ", lazyImage2)
                        lazyImage2.classList.remove("bg_lzy_img");
                        imgObserver2.unobserve(lazyImage2);
                    }
                },
                options)
        });
        const arr = document.querySelectorAll('.bg_lzy_img')
        arr.forEach((v) => {
            imageObserver2.observe(v);
        })
    });
})();

(function () {
    const callOrderCta = document.querySelectorAll('.call-cta');
    if (callOrderCta.length) {
        const callOrderBodyTag = document.getElementsByTagName('body')[0];
        const callOrderModalLayer = document.getElementById("callOrderModalLayer");
        const callOrderBtnClose = document.getElementById("callOrderBtnClose");
        const callOrderFormSubmit = document.getElementById("callOrderFormSubmit");
        const callOrderForm = document.getElementById("callOrderForm");
        const callOrderFormOk = document.getElementById("callOrderFormOk");
        const callOrderFormErr = document.getElementById("callOrderFormErr");
        const callOMB = document.getElementById("callOrderModal-body");
        const callOMBErr = document.getElementById("callOrderModal-body-err");
        const callOMBOk = document.getElementById("callOrderModal-body-ok");
        const call_contact_name = document.getElementById("call_contact_name");
        const call_contact_phone = document.getElementById("call_contact_phone");

        callOrderCta.forEach(callOrderItem => {
            callOrderItem.addEventListener("click", function (event) {
                event.preventDefault();
                callOrderBodySet();
            }, false);
        });

        function callOrderBodyReset() {
            callOrderBodyTag.style.overflowY = "auto";
            callOrderBodyTag.style.height = "auto";
            callOrderModalLayer.style.display = "none";
            callReSetForm();
            callOrderUnRegWinListen();
        }

        function callOrderBodySet() {
            callOrderModalLayer.style.display = "flex";
            callOrderBodyTag.style.overflowY = "hidden";
            callOrderBodyTag.style.height = "100%";
            callOrderRegWinListen();
        }

        function callSetFieldAlert(group_id) {
            group_id.classList.add('field-alert')
        }

        function callReSetFieldAlert(group_id) {
            group_id.classList.remove('field-alert')
        }

        function callCheckField(check_value, group_id, isEmail = false) {

            if (isEmail) {

                if (check_value === "" || !callValidateEmail(check_value)) {
                    callSetFieldAlert(group_id)
                    return false;
                } else {
                    callReSetFieldAlert(group_id)
                    return true;
                }

            } else {

                if (check_value === "") {
                    callSetFieldAlert(group_id)
                    return false;
                } else {
                    callReSetFieldAlert(group_id)
                    return true;
                }
            }
        }

        function callValidator() {
            let name = callCheckField(callOrderForm.call_contact_name.value, call_contact_name);
            let phone = callCheckField(callOrderForm.call_contact_phone.value, call_contact_phone);
            return name && phone;
        }

        function callValidateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        function callSendRequest() {
            // Пример отправки POST запроса:
            async function postData(url = '', data = {}) {
                // Default options are marked with *
                const response = await fetch(url, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        // 'Content-Type': 'application/json'
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                });
                return await response.json(); // parses JSON response into native JavaScript objects
            }

            postData('/wp-content/themes/ub1/php/mail.php', callGetAllFormFields())
                .then((data) => {
                    // console.log(data); // JSON data parsed by `response.json()` call
                    if (data.status === "success") {
                        // console.log(data);
                        callAfterBackEndValidation(true);
                    } else {
                        // console.log(data);
                        callAfterBackEndValidation(false);
                    }
                })
                .catch(() => {
                    // console.log("catch");
                    callAfterBackEndValidation(false);
                });
        }

        function callGetAllFormFields() {
            let fields = {};
            fields['call_contact_name'] = callOrderForm.call_contact_name.value;
            fields['call_contact_phone'] = callOrderForm.call_contact_phone.value;
            fields['contact_rand'] = callOrderForm.contact_rand.value;
            fields['contact_form'] = callOrderForm.contact_form.value;
            return fields;
        }

        function callAfterBackEndValidation( backendResult ) {
            // add reaction for
            callOMB.style.display = "none";
            if (backendResult) {
                callOMBOk.style.display = "flex";
            } else {
                callOMBErr.style.display = "flex";
            }
        }

        function callReSetForm() {
            callOMBOk.style.display = "none";
            callOMBErr.style.display = "none";
            callOMB.style.display = "flex";
            callOrderForm.call_contact_name.value = "";
            callOrderForm.call_contact_phone.value = "";
            callReSetFieldAlert(call_contact_name);
            callReSetFieldAlert(call_contact_phone);
        }


        callOrderBtnClose.onclick = function () {
            callOrderBodyReset();
        }

        callOrderFormSubmit.onclick = function (event) {
            event.preventDefault();
            if (callValidator()) {
                callSendRequest();
            }
        }

        callOrderFormOk.onclick = function (event) {
            event.preventDefault();
            callOrderBodyReset();
        }

        callOrderFormErr.onclick = function (event) {
            event.preventDefault();
            callOrderBodyReset();
        }

        callOrderForm.call_contact_name.onblur = function () {
            callCheckField(callOrderForm.call_contact_name.value, call_contact_name);
        }

        callOrderForm.call_contact_phone.onblur = function () {
            callCheckField(callOrderForm.call_contact_phone.value, call_contact_phone);
        }

        function callOrderRegWinListen() {
            window.addEventListener("click", function (event) {
                if (event.target === callOrderModalLayer) {
                    callOrderBodyReset();
                }
            }, false)
        }

        function callOrderUnRegWinListen() {
            window.removeEventListener("click", function (event) {
                if (event.target === callOrderModalLayer) {
                    callOrderBodyReset();
                }
            }, false);
        }
    }
})();