(function () {
    const reqOrderCta = document.querySelectorAll('#leave-request');
    if (reqOrderCta.length) {
        const reqOrderBodyTag = document.getElementsByTagName('body')[0];
        const reqOrderModalLayer = document.getElementById("reqOrderModalLayer");
        const reqOrderBtnClose = document.getElementById("reqOrderBtnClose");
        const reqOrderFormSubmit = document.getElementById("reqOrderFormSubmit");
        const reqOrderForm = document.getElementById("reqOrderForm");
        const reqOrderFormOk = document.getElementById("reqOrderFormOk");
        const reqOrderFormErr = document.getElementById("reqOrderFormErr");
        const reqOMB = document.getElementById("reqOrderModal-body");
        const reqOMBErr = document.getElementById("reqOrderModal-body-err");
        const reqOMBOk = document.getElementById("reqOrderModal-body-ok");
        const req_contact_name = document.getElementById("req_contact_name");
        const req_contact_company = document.getElementById("req_contact_company");
        const req_contact_email = document.getElementById("req_contact_email");
        const req_contact_message = document.getElementById("req_contact_message");

        reqOrderCta.forEach(reqOrderItem => {
            reqOrderItem.addEventListener("click", function (event) {
                event.preventDefault();
                reqOrderBodySet();
            }, false);
        });

        function reqOrderBodyReset() {
            reqOrderBodyTag.style.overflowY = "auto";
            reqOrderBodyTag.style.height = "auto";
            reqOrderModalLayer.style.display = "none";
            reqReSetForm();
            reqOrderUnRegWinListen();
        }

        function reqOrderBodySet() {
            reqOrderModalLayer.style.display = "flex";
            reqOrderBodyTag.style.overflowY = "hidden";
            reqOrderBodyTag.style.height = "100%";
            reqOrderRegWinListen();
        }

        function reqSetFieldAlert(group_id) {
            group_id.classList.add('field-alert')
        }

        function reqReSetFieldAlert(group_id) {
            group_id.classList.remove('field-alert')
        }

        function reqCheckField(check_value, group_id, isEmail = false) {

            if (isEmail) {

                if (check_value === "" || !reqValidateEmail(check_value)) {
                    reqSetFieldAlert(group_id)
                    return false;
                } else {
                    reqReSetFieldAlert(group_id)
                    return true;
                }

            } else {

                if (check_value === "") {
                    reqSetFieldAlert(group_id)
                    return false;
                } else {
                    reqReSetFieldAlert(group_id)
                    return true;
                }
            }
        }

        function reqValidator() {
            let name = reqCheckField(reqOrderForm.req_contact_name.value, req_contact_name);
            let company = reqCheckField(reqOrderForm.req_contact_company.value, req_contact_company);
            let email = reqCheckField(reqOrderForm.req_contact_email.value, req_contact_email, true);
            let message = reqCheckField(reqOrderForm.req_contact_message.value, req_contact_message);
            return name && company && email && message;
        }

        function reqValidateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        function reqSendRequest() {
            // Пример отправки POST запроса:
            async function postData(url = '', data = {}) {
                // Default options are marked with *
                const response = await fetch(url, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        // 'Content-Type': 'application/json'
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                });
                return await response.json(); // parses JSON response into native JavaScript objects
            }

            postData('/wp-content/themes/ub1/php/mail.php', reqGetAllFormFields())
                .then((data) => {
                    // console.log(data); // JSON data parsed by `response.json()` call
                    if (data.status === "success") {
                        // console.log(data);
                        reqAfterBackEndValidation(true);
                    } else {
                        // console.log(data);
                        reqAfterBackEndValidation(false);
                    }
                })
                .catch(() => {
                    // console.log('catch');
                    reqAfterBackEndValidation(false);
                });
        }

        function reqGetAllFormFields() {
            let fields = {};
            fields['req_contact_name'] = reqOrderForm.req_contact_name.value;
            fields['req_contact_company'] = reqOrderForm.req_contact_company.value;
            fields['req_contact_email'] = reqOrderForm.req_contact_email.value;
            fields['req_contact_message'] = reqOrderForm.req_contact_message.value;
            fields['contact_rand'] = reqOrderForm.contact_rand.value;
            fields['contact_form'] = reqOrderForm.contact_form.value;
            return fields;
        }

        function reqAfterBackEndValidation(backendResult) {
            // add reaction for
            reqOMB.style.display = "none";
            if (backendResult) {
                reqOMBOk.style.display = "flex";
            } else {
                reqOMBErr.style.display = "flex";
            }
        }

        function reqReSetForm() {
            reqOMBOk.style.display = "none";
            reqOMBErr.style.display = "none";
            reqOMB.style.display = "flex";
            reqOrderForm.req_contact_name.value = "";
            reqOrderForm.req_contact_company.value = "";
            reqOrderForm.req_contact_email.value = "";
            reqOrderForm.req_contact_message.value = "";
            reqReSetFieldAlert(req_contact_name);
            reqReSetFieldAlert(req_contact_company);
            reqReSetFieldAlert(req_contact_email);
            reqReSetFieldAlert(req_contact_message);
        }


        reqOrderBtnClose.onclick = function () {
            reqOrderBodyReset();
        }

        reqOrderFormSubmit.onclick = function (event) {
            event.preventDefault();
            if (reqValidator()) {
                reqSendRequest();
            }
        }

        reqOrderFormOk.onclick = function (event) {
            event.preventDefault();
            reqOrderBodyReset();
        }

        reqOrderFormErr.onclick = function (event) {
            event.preventDefault();
            reqOrderBodyReset();
        }

        reqOrderForm.req_contact_name.onblur = function () {
            reqCheckField(reqOrderForm.req_contact_name.value, req_contact_name);
        }

        reqOrderForm.req_contact_company.onblur = function () {
            reqCheckField(reqOrderForm.req_contact_company.value, req_contact_company);
        }

        reqOrderForm.req_contact_email.onblur = function () {
            reqCheckField(reqOrderForm.req_contact_email.value, req_contact_email, true);
        }

        reqOrderForm.req_contact_message.onblur = function () {
            reqCheckField(reqOrderForm.req_contact_message.value, req_contact_message);
        }

        function reqOrderRegWinListen() {
            window.addEventListener("click", function (event) {
                if (event.target === reqOrderModalLayer) {
                    reqOrderBodyReset();
                }
            }, false)
        }

        function reqOrderUnRegWinListen() {
            window.removeEventListener("click", function (event) {
                if (event.target === reqOrderModalLayer) {
                    reqOrderBodyReset();
                }
            }, false);
        }
    }
})();